// Import our external dependencies.
import Polyglot from "node-polyglot";
import list_of_languages from "./supported-langs";
import { setLocale as setTimeLocale, setUnknown } from "./time";

export { distanceInWords, formatDate, parseDate } from "./time";

// Cache their locale, defaulting to English.
export const locale = localStorage.getItem("locale") || "en-us";

// Initialize an instance of Airbnb's polyglot package.
const polyglot = new Polyglot({
  locale,
  phrases: {
    /* Loads via http. Cached in service worker. */
  },
  allowMissing: true,
  onMissingKey() {
    return "🔴";
  },
});

// Create an initialization hook.
let markAsReady = () => {};
export const ready = new Promise((resolve) => {
  markAsReady = () => {
    resolve();
    polyglot.isLocaleLoaded = true;
  };
});

// Make an HTTP request for the User's language file. This will be cached in the
// service worker.
loadLocale(locale, true);

export async function loadLocale(locale, skipReload) {
  localStorage.setItem("locale", locale);

  // Update date translations
  setTimeLocale(locale);

  // Update Polyglot's translations
  const response = await fetch(`i18n/${locale}.json`);
  const json = await response.json();
  polyglot.replace(json);
  setUnknown(translate("unknown"));

  // Alert the application to translations being completed
  markAsReady();
  if (skipReload) return json;
  document.location.reload();
}

// Expose the list of languages we support.
export const languages = list_of_languages;

// Export a function to get translated strings for display to the user.
export function translate(key, interpolationOptions = 1) {
  return polyglot.t(key, interpolationOptions);
}

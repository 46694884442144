// Import our external dependencies.
import { format, parseISO, formatDistance } from "date-fns";
import { enUS, ru } from "date-fns/locale";

let locale = enUS;
let unknown = "";

export function setUnknown(unknownStr = "") {
  unknown = unknownStr;
}

export function setLocale(newLocale) {
  switch (newLocale) {
    case "en-us": {
      locale = enUS;
      break;
    }
    case "ru": {
      locale = ru;
      break;
    }
    default:
      locale = enUS;
  }
}

export function distanceInWords(start, end) {
  return formatDistance(start, end, { locale });
}

export function formatDate(dateObj, pattern) {
  if (!dateObj || typeof dateObj.getMonth !== "function") return unknown;
  return format(dateObj, pattern, { locale });
}

export function parseDate(isoString) {
  return parseISO(isoString);
}

export default [
  {
    name: "Emoji ✌",
    id: "emoji",
  },
  {
    name: "English",
    id: "en-us",
  },
  {
    name: "русский",
    id: "ru",
  },
];
